import { useState, useRef, useEffect } from "react"
import { stateObjects2, statesObject } from "./states.tsx"

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}

const inputStyle = {
    display: "block",
    width: "100%",
    padding: "12px 14px",
    fontSize: "15px",
    fontFamily: "'ABC Diatype Regular', sans-serif",
    borderRadius: "8px",
    border: "1px solid #ccc",
    color: "rgb(51, 51, 51)",
}

const resultsStyle = {
    position: "absolute",
    zIndex: "1",
    background: "#fff",
    width: "100%",
    maxHeight: "200px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    overflow: "auto",
    borderRadius: 8,
    padding: 0,
    margin: 0,
    top: 2,
    fontFamily: "'ABC Diatype Regular', sans-serif",
}

const resultItemStyle = {
    padding: "10px",
    cursor: "pointer",
    textAlign: "left",
    fontSize: "16px",
    fontFamily: "'ABC Diatype Regular', sans-serif",
    color: "rgb(51, 51, 51)",
}

const hoveredResultStyle = {
    ...resultItemStyle,
    backgroundColor: "#F2F8F2",
}

function validateAndSetDefaultValue(defaultValue: string, returnKey: boolean) {
    return Object.keys(statesObject).includes(defaultValue)
        ? returnKey
            ? defaultValue
            : statesObject[defaultValue]
        : ""
}

interface Props {
    options: {
        text: string
        value: string
    }[]
    onChange(value: string): void
    required?: boolean
    name: string
    id?: string
    defaultValue?: string
}

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function TypeaheadSelect({
    options,
    onChange,
    required,
    name,
    id,
    defaultValue,
}: Props) {
    const [inputValue, setInputValue] = useState(
        validateAndSetDefaultValue(defaultValue, false)
    )
    const [showResults, setShowResults] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState(options)
    const [scrollTo, setScrollTo] = useState<number>()
    const [hoveredResult, setHoveredResult] = useState<number>()
    const [selectedValue, setSelectedValue] = useState(
        validateAndSetDefaultValue(defaultValue, true)
    )
    const resultContainerRef = useRef()
    const resultRefs = useRef([])
    const inputRef = useRef()

    useEffect(() => {
        setInputValue(validateAndSetDefaultValue(defaultValue, false))
        setSelectedValue(validateAndSetDefaultValue(defaultValue, true))
    }, [defaultValue])

    const getSelectedOption = (val) => {
        return options.find((opt) => opt.value === val)
    }

    // Fired when the input is changed
    const handleChange = (e) => {
        const selectedOption = getSelectedOption(selectedValue)

        // if the user has selected a value, then enters a new value in the text box
        // we want to clear the existing value when they start typing
        if (selectedValue) {
            const newInputValue = e.target.value.replace(
                selectedOption.text,
                ""
            )
            setInputValue(newInputValue)
            filterOptions(newInputValue)
        } else {
            setInputValue(e.target.value)
            filterOptions(e.target.value)
        }

        setShowResults(e.target.value !== "")
        setHoveredResult(0)
    }

    const filterOptions = (value) => {
        setFilteredOptions(
            options.filter((option) =>
                option.text.toLowerCase().includes(value.toLowerCase())
            )
        )
    }

    const handleSelectOption = (option) => {
        setShowResults(false)
        setInputValue(option.text)
        setSelectedValue(option.value)
        onChange && onChange(option.value)
    }

    // scroll to selected option in container with list all options
    // set 0 delay because list all options may not render in time
    const scrollToSelectedOption = (index) =>
        setTimeout(() => {
            if (resultRefs?.current?.[index] && resultContainerRef?.current) {
                resultContainerRef.current.scrollTop =
                    resultRefs.current[index].offsetTop
            }
        }, 0)

    const handleInputFocus = () => {
        setShowResults(true)
        setFilteredOptions(options)
        const index = options.findIndex(
            (result) => result.value === selectedValue
        )
        setScrollTo(index)
        // resultRefs.current[scrollTo]?.scrollIntoView()
        scrollToSelectedOption(scrollTo)
        if (index) {
            setHoveredResult(index)
        }

        inputRef?.current?.setSelectionRange(0, 0)
    }

    const handleKeyUp = (e) => {
        if (e.key === "Enter") {
            // don't submit the form on enter
            e.preventDefault()
        }
    }

    const handleKeyDown = (e) => {
        switch (e.key) {
            case "Escape":
                return loseInputFocus()
            case "Enter":
                if (filteredOptions.length === 1) {
                    handleSelectOption(filteredOptions[0])
                } else if (hoveredResult !== null) {
                    const selected = filteredOptions[hoveredResult]
                    handleSelectOption(selected)
                }
                return
            case "ArrowDown":
                if (hoveredResult === null) {
                    return setHoveredResult(0)
                } else if (hoveredResult === filteredOptions.length - 1) {
                    return
                }

                setHoveredResult((old) => old + 1)
                if (hoveredResult > 3) {
                    // resultRefs.current[hoveredResult + 1]?.scrollIntoView(false)
                    scrollToSelectedOption(hoveredResult + 1)
                }

                break
            case "ArrowUp":
                if (hoveredResult === null) {
                    return setHoveredResult(0)
                } else if (hoveredResult === 0) {
                    return
                }

                setHoveredResult((old) => old - 1)
                if (hoveredResult > 3) {
                    // resultRefs.current[hoveredResult - 1]?.scrollIntoView(false)
                    scrollToSelectedOption(hoveredResult - 1)
                }

                break
            default:
                break
        }
    }

    const loseInputFocus = (clickOutside = false) => {
        setShowResults(false)
        setHoveredResult(null)

        // If there is a previously selected value, we should
        // revert to that when the input is blurred if the user
        // doesn't select another
        if (clickOutside) {
            return null
        }
        if (selectedValue) {
            setInputValue(getSelectedOption(selectedValue).text)
        } else {
            setInputValue("")
        }
    }

    const handleInputBlur = () => {
        loseInputFocus()
    }

    const handleCaretClick = () => {
        if (showResults) {
            return handleInputBlur()
        }
        handleInputFocus()
    }

    useEffect(() => {
        if (scrollTo !== null) {
            setTimeout(() => {
                // resultRefs.current[scrollTo]?.scrollIntoView()
                scrollToSelectedOption(scrollTo)
            }, 10)
        }
    }, [scrollTo])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                loseInputFocus(true)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [inputRef])

    return (
        <div style={{ textAlign: "left" }}>
            <div style={{ position: "relative" }}>
                <input type="hidden" name={name} value={selectedValue} />
                <input
                    id={id}
                    ref={inputRef}
                    placeholder="Select your state"
                    name={`${name}_input`}
                    value={inputValue}
                    autoComplete="off"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    style={inputStyle}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    required={required}
                />
                <div
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "14px",
                        top: "8px",
                        fontSize: "18px",
                        color: "#ccc",
                    }}
                    onClick={handleCaretClick}
                >
                    &#8964;
                </div>
            </div>
            {showResults && (
                <div style={{ position: "relative" }}>
                    <ul
                        className="typeahead-results"
                        style={resultsStyle}
                        ref={resultContainerRef}
                    >
                        {filteredOptions.map((option, index) => (
                            <li
                                style={
                                    hoveredResult === index
                                        ? hoveredResultStyle
                                        : resultItemStyle
                                }
                                key={option.value}
                                onMouseEnter={() => setHoveredResult(index)}
                                onMouseLeave={() => setHoveredResult(null)}
                                onMouseDown={() => handleSelectOption(option)}
                                ref={(el) => (resultRefs.current[index] = el)}
                            >
                                {option.text}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

TypeaheadSelect.defaultProps = {
    options: stateObjects2,
}
